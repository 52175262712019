// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/bg_login.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/bg_blue.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".text-align-c[data-v-018c29fa]{text-align:center}.my-icon[data-v-018c29fa]{font-size:20px;color:#fff;margin-right:5px}.title[data-v-018c29fa]{font-size:20px;margin-top:40%}.label[data-v-018c29fa],.title[data-v-018c29fa]{text-align:center}.label[data-v-018c29fa]{color:#fff;font-size:16px}.el-header[data-v-018c29fa]{background-color:#409eff;color:#333;line-height:60px}.container[data-v-018c29fa]{width:400px;margin:0 auto}.container-form[data-v-018c29fa]{box-sizing:border-box}.border-radius-top[data-v-018c29fa]{border-top-left-radius:6px;border-top-right-radius:6px;overflow:hidden}.border-radius-buttom[data-v-018c29fa]{border-bottom-left-radius:6px;border-bottom-right-radius:6px;overflow:hidden}.el-aside[data-v-018c29fa]{color:#333}.width-100[data-v-018c29fa]{width:100%}.text-align-footer[data-v-018c29fa]{width:100%;text-align:center;color:#fff;margin-top:100px}.bg[data-v-018c29fa]{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100vh;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;position:relative;z-index:0;overflow:hidden}.bg .half_bg[data-v-018c29fa]{width:100%;left:50%;position:absolute;right:0;top:0;height:100vh;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");position:relative;z-index:1}.bg .dropdown[data-v-018c29fa]{position:absolute;right:0;top:0;margin-right:20px;z-index:2}.bg .dropdown .lang-btn[data-v-018c29fa]{color:#6da7e0;font-size:18px}.login-form[data-v-018c29fa]{position:absolute;border-radius:6px;background:hsla(0,0%,100%,.6);width:400px;padding:25px;margin-top:60px;right:0;margin-right:200px;z-index:2}", ""]);
// Exports
module.exports = exports;

<!--
 * @Author       : JiangChao
 * @Date         : 2022-03-31 08:51:46
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-07 13:53:43
 * @Description  : 登录页
-->
<template>
  <div class="bg">
    <div class="half_bg"></div>
    <div class="dropdown">
      <el-dropdown trigger="click" @command="handleCommand">
        <div class="lang-btn">
          {{ $t("language_settings") }}
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="zh"> {{ $t("chinese") }}</el-dropdown-item>
          <el-dropdown-item command="en"> {{ $t("english") }}</el-dropdown-item>
          <el-dropdown-item command="de"> {{ $t("german") }}</el-dropdown-item>
          <el-dropdown-item command="ja">
            {{ $t("japanese") }}</el-dropdown-item
          >
          <el-dropdown-item command="ko"> {{ $t("korean") }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 登录注册 -->
    <LoginRegister
      class="login-form"
      :regionArr="regionArr"
      v-show="showLogin"
      @showForgetDialog="showForgetDialog"
    ></LoginRegister>
    <!-- @showForget="showForgetDialog()" -->
    <ForgetPassword
      v-show="showForget"
      class="login-form"
      :regionArr="regionArr"
      @showLoginDialog="showLoginDialog"
    ></ForgetPassword>
    <!--  @showLogin="showLoginDialog()" -->
  </div>
</template>

<script>
import LoginRegister from "../login/components/LoginRegister.vue";
import ForgetPassword from "../login/components/ForgetPassword.vue";
import {
  selectApi,
  updateBaseUrl,
  // languageKeyApi
} from "@/api/api.js";
export default {
  data() {
    return {
      showLogin: true,
      showForget: false,
      regionArr: [], //国家/区域
      apiDomain: "",
      isDebugMode: process.env.NODE_ENV === "development",
    };
  },
  components: { LoginRegister, ForgetPassword },

  methods: {
    getRegion() {
      selectApi({ type: "AreaType" }).then((r) => {
        console.log("=====", r);
        this.regionArr = r.data;
      });
    },
    // getLanguageKey() {
    //   languageKeyApi().then((r) => {
    //     localStorage.setItem("lang", JSON.stringify(r.data));
    //   });
    // },
    handleCommand(command) {
      console.log("command: ", command);
      this.$store.dispatch("updateSelectedLanguage", command);
      localStorage.setItem("selectedLanguage", command);
    },
    showForgetDialog(val) {
      console.log("showForgetDialog---val: ", val);
      this.showLogin = false;
      this.showForget = true;
    },
    showLoginDialog(val) {
      console.log("showLoginDialog---val: ", val);
      this.showLogin = true;
      this.showForget = false;
    },
  },
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    // localStorage.setItem("isChina", JSON.stringify(true));
    // 获取当前页面的域名
    const currentHost = window.location.host;
    const currentProtocol = window.location.protocol;
    //登录成功后，跳转不同的接口域名
    if (this.isDebugMode) {
      updateBaseUrl("http://logcloud.sigmawit.com");
    } else {
      this.apiDomain = currentProtocol + "//" + currentHost;
      updateBaseUrl(this.apiDomain);
    }
    this.getRegion();
    // this.getLanguageKey();
  },
};
</script>

<style scoped lang="less">
@import "./Login.less";
.bg {
  display: flex;
  flex-direction: column; /* 设置列方向，让子元素垂直排列 */
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url("../../assets/bg_login.png");
  background-size: cover;
  position: relative;
  z-index: 0;
  overflow: hidden;
  .half_bg {
    width: 100%;
    left: 50%;
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    background-image: url("../../assets/bg_blue.png");
    position: relative;
    z-index: 1;
  }
  .dropdown {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 20px;
    z-index: 2;
    .lang-btn {
      color: #6da7e0;
      font-size: 18px;
    }
  }
}

.login-form {
  position: absolute;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.6);
  width: 400px;
  padding: 25px;
  margin-top: 60px;
  right: 0;
  margin-right: 200px;
  z-index: 2; // 确保 login-form 在 half_bg 之上
}
</style>

<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-17 11:16:26
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-05 16:54:06
 * @Description  : 忘记密码
-->
<template>
  <div class="container">
    <div class="title">
      <span class="font-heavy">{{ $t("forgot_password") }}</span>
    </div>
    <el-form
      :model="forgetForm"
      status-icon
      ref="forgetRef"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item :label="$t('country_region')">
        <el-select
          v-model="forgetForm.region"
          :placeholder="$t('please_select_a_country_or_region')"
          @change="onChange"
          style="width: 250px"
        >
          <el-option
            :label="$t(item.code)"
            :value="item.code"
            v-for="(item, index) in regionArr"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('account')">
        <el-input
          clearable
          :placeholder="placeholderTip"
          v-model="forgetForm.subject"
        >
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('verification_code')">
        <el-input
          v-model="forgetForm.verifyCode"
          autocomplete="off"
          :placeholder="$t('verification_code')"
        >
          <template #append>
            <div @click="getSMSCode" class="send">
              {{ counting ? `${timerCount}s` : $t("get_verification_code") }}
            </div>
          </template>
        </el-input>
      </el-form-item>

      <el-form-item :label="$t('reset_password')">
        <el-input
          prefix-icon="el-icon-unlock"
          :placeholder="$t('reset_password')"
          show-password
          clearable
          :type="passwordType"
          v-model="originalPassword"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="checkPass" :label="$t('confirm_password')">
        <el-input
          prefix-icon="el-icon-unlock"
          :placeholder="$t('confirm_password')"
          :type="passwordType"
          show-password
          clearable
          v-model="originalPassword2"
          autocomplete="off"
        >
        </el-input>
      </el-form-item>
      <el-form-item label-width="0px">
        <el-button
          round
          class="width-100"
          type="primary"
          @click="modifyPsw()"
          >{{ $t("confirm") }}</el-button
        >
      </el-form-item>
      <el-form-item label-width="0px">
        <el-button
          round
          class="width-100"
          type="primary"
          @click="backToLogin()"
          >{{ $t("key_back") }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  verifyCodeModifyPwdApi,
  forgetResetPwdApi,
  updateBaseUrl,
} from "@/api/api.js";
import { RegexUtils } from "@/utils/validate/regex";
import { encrypt } from "@/utils/RSAUtil";

export default {
  data() {
    return {
      // loginForm: {
      // username: "",
      // },
      forgetForm: {
        region: "",
        mobileNum: "",
        email: "",
        subject: "",
        verifyId: "",
      },
      passwordType: "password",
      suffixIcon: "el-icon-circle-check",
      isActive: 0,
      isRegister: false,
      timerCount: 0,
      counting: false, // 是否计时中
      interval1: null,
      yzBtnEnable: true,
      isChina: true,
      loginOriginalPassword: "",
      originalPassword: "",
      originalPassword2: "",
      placeholderTip: "",
      isDebugMode: process.env.NODE_ENV === "development",
      apiDomain: "",
      currentHost: window.location.protocol + "//" + window.location.host, // 当前页面的完整URL
      // regionArr: [
      //   {
      //     code: "D_China",
      //     name: "China",
      //     value: "https://logcloud-cn.sigmawit.com",
      //     remark: "中国",
      //   },
      //   {
      //     code: "D_EUA",
      //     name: "Europe",
      //     value: "https://logcloud-eu.sigmawit.com",
      //     remark: "欧洲",
      //   },
      //   {
      //     code: "D_TEST",
      //     name: "Test",
      //     value: "http://localhost:3000",
      //     remark: "测试",
      //   },
      // ],
    };
  },
  methods: {
    modifyPsw() {
      if (!this.forgetForm.region) {
        this.$message.error(this.$t("please_select_country_or_region"));

        return;
      }
      if (this.isChina && !this.forgetForm.subject) {
        this.$message.error(
          this.$t("please_enter_your_mobile_number_or_email_address")
        );

        return;
      }

      if (!this.isChina && !this.forgetForm.subject) {
        this.$message.error(this.$t("please_enter_your_email_address"));

        return;
      }
      if (!this.forgetForm.verifyId) {
        this.$message.error(this.$t("verification_code_cannot_be_empty"));

        return;
      }
      if (!this.originalPassword || !this.originalPassword2) {
        this.$message.error(this.$t("password_cannot_be_empty"));

        return;
      }

      if (this.originalPassword != this.originalPassword2) {
        this.$message.error(
          this.$t("the_two_password_inputs_are_inconsistent")
        );

        return;
      }

      let timestamp = new Date().getTime();
      let params = {
        ...this.forgetForm,
        salt: timestamp,
        mobileNum: this.forgetForm.subject,
        email: this.forgetForm.subject,
        password: encrypt(this.originalPassword + timestamp),
      };
      console.log("params: ", params);

      forgetResetPwdApi(params).then((r) => {
        console.log("=====", r);
        this.$message.success(this.$t("modify_success_please_log_in_again"));
        this.$emit("showLoginDialog", true);
      });
    },
    onChange(val) {
      console.log("this.isDebugMode: ", this.isDebugMode);
      const index = this.regionArr.findIndex((item) => {
        return item.code === val;
      });
      console.log("index: ", index);
      // 保存选择到localStorage
      // localStorage.setItem("selectedRegion", val);
      switch (val) {
        case "D_China":
          localStorage.setItem("isChina", JSON.stringify(true));
          localStorage.setItem("selectedLanguage", "zh");
          this.$store.dispatch("updateSelectedLanguage", "zh");
          this.isChina = true;
          break;
        default:
          localStorage.setItem("isChina", JSON.stringify(false));
          localStorage.setItem("selectedLanguage", "en");
          this.$store.dispatch("updateSelectedLanguage", "en");
          this.isChina = false;
          break;
      }
      this.placeholderTip =
        this.isChina == true ? this.$t("phone_email") : this.$t("email");
      this.apiDomain = this.regionArr[index].value;
      console.log("this.apiDomain: ", this.apiDomain);
      // this.currentHost="https://logcloud-cn.sigmawit.com/forgetPassword"
      //更换不同的接口域名
      if (this.isDebugMode) {
        updateBaseUrl("http://logcloud.sigmawit.com");
      } else {
        if (this.apiDomain !== this.currentHost) {
          // 如果不同，则重定向到新的域名
          window.location.href = this.apiDomain;
        }
        updateBaseUrl(this.apiDomain);
      }
      // if (this.isDebugMode) {
      //   window.location.href =
      //     "http://localhost:3000/forgetPassword?region=" +
      //     encodeURIComponent(val);
      // } else {
      //   window.location.href =
      //     this.regionArr[index].value +
      //     "/forgetPassword?region=" +
      //     encodeURIComponent(val);
      // }
    },
    clearIntervalHandel() {
      clearInterval(this.interval1);
      this.yzBtnEnable = true;
      this.counting = false;
    },
    getSMSCode() {
      if (!this.forgetForm.region) {
        this.$message.error(this.$t("please_select_country_or_region"));

        return;
      }
      if (this.isChina && !this.forgetForm.subject) {
        this.$message.error(
          this.$t("please_enter_your_mobile_number_or_email_address")
        );
        return;
      }
      if (!this.isChina && !this.forgetForm.subject) {
        this.$message.error(this.$t("please_enter_your_email_address"));
        return;
      }
      //
      if (!this.isChina && !RegexUtils.isEmail(this.forgetForm.subject)) {
        this.$message.error(this.$t("the_email_format_is_incorrect"));

        return;
      }

      // if (this.isChina && !RegexUtils.checkMobile(this.forgetForm.mobileNum)) {
      //   this.$message.error(this.$t("the_phone_number_format_is_incorrect"));
      //   return;
      // }
      let timer = 180;
      const loading = this.$loading({
        lock: true,
        text: this.$t("obtaining_verification_code"),

        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let params = { subject: this.forgetForm.subject };

      console.log("params: ", params);

      verifyCodeModifyPwdApi(params).then((res) => {
        loading.close();
        console.log("verifyCodeRegisterApi----" + JSON.stringify(res));
        if (res) {
          this.$message.success(this.$t("verification_code_sent_successfully"));

          this.interval1 = setInterval(() => {
            timer -= 1;
            if (timer <= 0) {
              this.clearIntervalHandel();
            } else {
              this.timerCount = timer;
              this.counting = true;
              this.yzBtnEnable = false;
            }
          }, 1000);
          this.forgetForm.verifyId = res.data;
        }
      });
    },
    backToLogin() {
      // this.$router.go(-1);
      this.$emit("showLoginDialog", true);
    },
    setDefaultRegion() {
      // 根据当前域名设置默认选中的地区
      // this.currentHost="https://logcloud-eu.sigmawit.com"
      console.log("this.currentHost: ", this.currentHost);
      const currentHost = this.currentHost.split("//")[1]; // 只取主机名部分
      console.log("currentHost: ", currentHost);
      console.log("this.regionArr: ", JSON.stringify(this.regionArr));

      const matchedItem = this.regionArr.find((item) => {
        console.log("item: ", JSON.stringify(item)); // 打印每个检查过的item
        return item.value.includes(currentHost);
      });
      console.log("matchedItem: ", JSON.stringify(matchedItem));
      if (matchedItem) {
        this.forgetForm.region = matchedItem.code;
        this.onChange(matchedItem.code); // 触发onChange事件以更新其他依赖于region的属性
      }
    },
  },
  props: {
    regionArr: {
      type: Array,
      required: true,
    },
  },
  computed: {
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    // placeholderTip() {
    //   console.log("this.isChina: ", this.isChina);
    //   // 根据isChina的值返回不同的字符串
    //   return this.isChina == true ? this.$t("phone_email") : this.$t("email");
    // },
  },
  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
      // 当国际化语言变化时，重新计算 placeholderTip
      this.placeholderTip =
        this.isChina == true ? this.$t("phone_email") : this.$t("email");
    },
    // 监听 regionArr 的变化
    regionArr: {
      immediate: true, // 立即执行一次
      handler(newVal) {
        if (newVal.length > 0) {
          this.setDefaultRegion();
        }
      },
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    console.log("this.isChina: ", this.isChina);
    this.placeholderTip =
      this.isChina == true ? this.$t("phone_email") : this.$t("email");
    this.setDefaultRegion();
    // 在页面加载时从URL获取region参数
    // const urlParams = new URLSearchParams(window.location.search);
    // console.log("urlParams: ", JSON.stringify(urlParams));
    // const regionParam = urlParams.get("region");

    // if (regionParam) {
    //   this.forgetForm.region = decodeURIComponent(regionParam);
    // }
  },
};
</script>

<style scoped lang="less">
.container {
  .title {
    display: flex;
    height: 50px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
  }
  .tab-box {
    //   height: 28px;
    //   border-radius: 18px;
    display: flex;
    //   margin-top: 20px;
    //   margin-left: 10px;
    //   margin-right: 10px;
    width: 400px;
    background: white;
    flex-direction: row;
    //   justify-content: space-evenly;
    align-items: center;
    line-height: 48px;
    height: 48px;
  }
  .tab-item {
    //   border-radius: 18px;
    //   margin: 8px;
    font-size: 14px;
    width: 200px;
    text-align: center;

    //   padding: 0px 4px;
    justify-content: center;
    background: #edeeef;
  }

  .is-active {
    background-color: #86c6ff;
    font-size: 16px;
    font-weight: Normal;
    text-align: center;
    line-height: 48px;
    height: 48px;

    color: #ffffff;
  }
  .send {
    font-size: 14px;
    font-weight: Normal;
    text-align: center;
    color: #6da7e0;
    line-height: 18px;
  }
  .demo-ruleForm {
    margin-top: 20px;
  }
}
.width-100 {
  width: 100%;
}
</style>
